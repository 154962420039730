/* Pagination Number Button styles. */
.buttonContainer:hover{
    box-shadow: #E6E6E6 -2px 0px 0px inset, #E6E6E6 0px -2px 0px inset, #E6E6E6 2px 0px 0px inset, #E6E6E6 0px 2px 0px inset !important;
}

.selectedButtonContainer #number{
    color: #ff4848 !important;
}

/* Pagination Icon Button styles. */
.iconContainer:hover {
    box-shadow: #ff4848 -1px 0px 0px inset, #ff4848 0px -1px 0px inset, #ff4848 1px 0px 0px inset, #ff4848 0px 1px 0px inset !important;
}
.iconContainer:active {
    background-color: #ff4848 !important;
    color: #ffffff !important;
    box-shadow: #ff4848 -1px 0px 0px inset, #ff4848 0px -1px 0px inset, #ff4848 1px 0px 0px inset, #ff4848 0px 1px 0px inset !important;
}

.iconContainer:active #iconComponent {
    color: #ffffff !important;
}

/* Primary Button styles */
.primary:hover{
    background-color: #135b9e !important;
}
.primary:active{
    background-color: #404040 !important;
}

.primary:hover #icon{
    background-color: #135b9e !important;
}
.primary:active #icon{
    background-color: #404040 !important;
}


/* Ghost button styles */
.ghost:hover{
    background-color: #F1F1F1 !important;
    border-color: #e6e6e6 !important;
    color: #5F5F61 !important;
}
.ghost:active{
    background-color: #FFECEC !important;
    box-shadow: #ff4848 -1px 0px 0px inset, #ff4848 0px -1px 0px inset, #ff4848 1px 0px 0px inset, #ff4848 0px 1px 0px inset !important;
    color: #ff4848 !important;
}

.ghost:hover #icon {
    color: #5F5F61 !important;
    
}
.ghost:active #icon {
    color: #ff4848 !important;
}

/* Text Button styles */
.text:hover{
    color: #222124 !important;
}

.text:active {
    color: #ff4848 !important;
}

.text:hover #icon{
    color: #222124 !important;
}

.text:active  #icon{
    color: #ff4848 !important;
}