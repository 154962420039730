body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 0px;
}

a,
br,
button,
div,
form,
h1,
h2,
h3,
h4,
h5,
h6,
img,
input,
label,
li,
link,
select,
span,
svg,
table,
td,
th,
textarea,
thead,
title,
tr,
p {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter';
}

#editor div {
  font-family: "Arial";
}

#previewMailBodyData span,
#editor span {
  font-family: inherit;
}

#editor-preview * {
  padding: unset;
  margin: unset;

}

#editor-preview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

#editor * {
  padding: unset;
  margin: unset;
}



input[type="text"],
input[type="password"],
textarea,
select,
input {
  outline: none;
  color: #102F51;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



input:-webkit-autofill {
  -webkit-box-shadow: 200px 200px 100px white inset;
  box-shadow: 200px 200px 100px white inset;
}

/* Custom Dropdown */

.custom-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.partial-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.custom-checkbox .checkmark {
  width: 14px;
  height: 14px;
  border: 1px solid #d3dae3;
  display: inline-block;
  border-radius: 3px;
  background: white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") center no-repeat;
}

.partial-checkbox .checkmark {
  width: 14px;
  height: 14px;
  border: 1px solid #FF4848;
  display: inline-block;
  border-radius: 3px;
  background-size: 64%;
  background: white url("../public/assets/icons/red_minus.svg") center no-repeat;
}

.partial-checkbox .checkmark:hover {
  width: 14px;
  height: 14px;
  border: 1px solid #404040;
  display: inline-block;
  border-radius: 3px;
  background: white url("../public/assets/icons/black_minus.svg") center no-repeat;
}

.custom-checkbox .checkmark:hover {
  width: 14px;
  height: 14px;
  border: 1px solid #404040;
  display: inline-block;
  border-radius: 3px;
  background: white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") center no-repeat;
}

/* color,background color changed by upali red to #FF4848 */
.custom-checkbox input:checked+.checkmark {
  background-size: 64%;
  background-color: #FF4848;
  border: 1px solid #FF4848;
}

.custom-checkbox input:disabled+.checkmark {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #f4f4f4;
  cursor: not-allowed !important;
}

/* .custom-checkbox input:checked:disabled+.checkmark {
  background-size: 64%;
  color: #FF4848;
  background-color: #FF4848;
  border: 1px solid #FF4848;
} */
/* .custom-checkbox input:disabled+.checkmark {
  border: 1px solid #EEEEEE;
} */

.custom-checkbox input {
  display: none;
}

.partial-checkbox input {
  display: none;
}

.green-custom-checkbox .green-checkmark {
  width: 16px;
  height: 16px;
  border: 1px solid #d3dae3;
  display: inline-block;
  border-radius: 3px;
  margin-right: 8px;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") center/1050% no-repeat;
}

.green-custom-checkbox input:checked+.green-checkmark {
  background-size: 65%;
  color: red;
  background-color: red;
  border: 1px solid red;
}

.green-custom-checkbox input:checked+.green-checkmark {
  background-size: 65%;
  color: #13CA7A;
  background-color: #13CA7A;
  border: 1px solid #13CA7A;
}

.green-custom-checkbox input {
  display: none;
}

/* Custom Dropdown */


.css-hped4j.SnackbarContent-root {
  border-radius: 15px !important;
}

.css-96yce9 .SnackbarItem-message {
  padding: 0 !important;
}

/* Variant Error for snackbar message */
.css-96yce9 .SnackbarItem-variantError {
  background-color: #FCEDEA !important;
  border: 1px solid #EA4E2C !important;
}


.drag-area {
  border: 2px dashed #fff;
  height: 500px;
  width: 700px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-area.active {
  border: 2px solid #fff;
}

.drag-area .icon {
  font-size: 100px;
  color: #fff;
}

.drag-area header {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
}

.drag-area span {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  margin: 10px 0 15px 0;
}

.drag-area button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #fff;
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.drops {
  color: rgb(255, 30, 0);
  font-size: 16px;
  border: 1px solid grey;
  padding: 22px;
  cursor: pointer;
}

.drops:hover {
  color: #F39C12 !important;
  background-color: red !important;

}

.drops:after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 1em;
  background-color: #34495E;
  pointer-events: none;
}

/* Content editable styles */
[contenteditable] {
  outline: 0px solid transparent;
}


/*stepper step1 styles*/
.ul {
  display: flex;
  margin-left: -9px;
  list-style-type: none;
}

.li {
  display: inline-block;

}

.input[type="checkbox"][id^="myCheckbox"] {
  display: none;
}

.label {
  padding: 25px;
  position: relative;
  background-color: white;
  cursor: pointer;
  border: 1px solid #D3DAE3;
  border-radius: 6px;
  display: block;
  margin-left: 10px;
}

.label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 2%;
  border: 1px solid grey;
  position: absolute;
  top: 3px;
  right: 5px;
  width: 14px;
  height: 14px;
  text-align: center;
  background: #FFFFFF;
  border-radius: 20%;
  /* Border */

  border: 1.5px solid #D3DAE3;
  /* transition-duration: 0.4s; */
  /* transform: scale(0); */
}

.label img {
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked+.label {
  border-color: #ddd;
}

:checked+.label:before {
  width: 14px;
  height: 14px;
  border: 1px solid #d3dae3;
  display: inline-block;
  border-radius: 3px;
  background: #FF4848 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") center no-repeat;

}

:checked+.label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  cursor: se-resize;
  right: 0;
}

.react-grid-item>.react-resizable-handle:after {
  border-bottom: 0px solid #FF4848;
  border-right: 0px solid #FF4848;
  bottom: -3px;
  content: "";
  height: 18px;
  position: absolute;
  right: 0;
  width: 18px;


}

.react-grid-item>.react-resizable-handle:hover {
  border: "1px solid #FF4848";
  box-shadow: "0px 5px 6px #E7E7E7",
}

.svg-container {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 30px;
  bottom: 0px;
  margin: 0px;
}

.css-1nuo49t-MuiButtonBase-root-MuiTab-root {
  text-transform: none !important;
  /* color:red !important */
}

/* Calendar Css */
/* 
 .css-nk89i7-MuiPickersCalendarHeader-root {
  background-color: red !important;
   /* opacity: 0.1; */
/* margin-top: 0px !important;
}
 .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #FF4848 !important;
  box-shadow: 0px 2px 5px rgb(255 72 72 / 33%) !important;
  color:#fff!important
 }
  .css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #FF4848 !important;
  box-shadow: 0px 7px 14px #FF4848(255, 72, 72, 0.33) !important;
} .css-nk89i7-MuiPickersCalendarHeader-root {
  background-color: #FF4848 !important;
  color: white;
  /* opacity: 0.3; 
  padding:1.5rem;
  margin-top: 0px !important;;
}
 .css-169iwlq-MuiCalendarPicker-root  {
  margin: 0 !important;
  width: auto !important;
}
.css-epd502 {
  margin: 0 !important;
  width: auto !important;
}
 .css-1ae9t7h-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button.Mui-disabled{
  color:white;
}

.css-jro82b-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button.Mui-disabled{
color:white;

}
.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon{
display:none;
}  */

#basic-menu[aria-hidden="true"] {
  display: none;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.editorSelected {
  background-color: #D3DAE3 !important;
}

.helper.reactour__helper {
  min-width: clamp(200px, 22.396vw, 500px);
  max-width: clamp(200px, 22.396vw, 500px);
  padding: 20px;
}

.helper .reactour__close {
  position: relative;
  top: -17.5px;
  right: 0;
}

.helper button[data-tour-elem="left-arrow"] {
  display: none;
}

.helper button[data-tour-elem="right-arrow"] span,
.helper button[data-tour-elem="left-arrow"] span,
.nextbtn {

  border-radius: 8px;
  color: #ff4848;
  font-size: 16px !important;
  font-weight: 600;
}

.helper button[data-tour-elem="right-arrow"]:disabled span,
.helper button[data-tour-elem="left-arrow"]:disabled span {
  color: rgba(98, 107, 126, 0.25);
}

.helper .tour-steps__wrap+div[data-tour-elem="controls"] {
  justify-content: end;
}

.helper .reactour__close svg {
  display: none;
}

.helper .reactour__close::before {
  content: "Skip";
  font-size: 16px;
  font-weight: 600;
  color: #626B7E;
}

.tour-steps__wrap h4 {
  font-size: 20px;
  color: #212833;
  font-weight: 600;
  margin-bottom: 10px;
}

.tour-steps__wrap p {
  font-size: 14px;
  color: #212833;
  font-weight: 400;
  line-height: 1.5;

}

.blink {
  top: 0%;
  left: 0%;
  transform: translate(-200%, 0%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 10px;
  height: 10px;

  border-radius: 50%;
  -webkit-animation: cd-pulse 2s infinite;
  -moz-animation: cd-pulse 2s infinite;
  animation: cd-pulse 2s infinite;
  -webkit-animation-delay: .5s;
  -moz-animation-delay: .5s;
  animation-delay: .5s;
  display: none;
}

.blinkbottom {
  top: 0%;
  left: 0%;
  transform: translate(0%, -250%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 10px;
  height: 10px;

  border-radius: 50%;
  -webkit-animation: cd-pulse 2s infinite;
  -moz-animation: cd-pulse 2s infinite;
  animation: cd-pulse 2s infinite;
  -webkit-animation-delay: .5s;
  -moz-animation-delay: .5s;
  animation-delay: .5s;
  display: none;

}

@keyframes cd-pulse {
  0% {
    box-shadow: 0 0 0 0 #ff4848
  }

  100% {



    box-shadow: 0 0 0 20px transparent
  }
}

.blink2 {
  width: 10px;
  height: 10px;
  display: block;
  background: #ff4848;
  border-radius: 50%;
  display: none;
}

.buttons {
  display: flex;
  background: #0F2F51;
  color: #494C52;
  font-weight: 500;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  border: 1px solid #D3DAE3;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 10px;
  align-self: center;
}

@keyframes glowing {
  0% {
    background-color: #a6b4a2;
    box-shadow: 0 0 5px #aab1a7;
    color: #849181
  }

  50% {
    background-color: #E4EBE1;
    box-shadow: 0 0 20px #E4EBE1;
  }

  100% {
    background-color: #E4EBE1;
    box-shadow: 0 0 5px #DAE0D8;
  }
}

.buttons {
  animation: glowing 1800ms;
}


/* tooltip custom visibility css */
.tooltipMainContainer:hover #tooltip {
  /* visibility: visible; */
  display: block;
}

.tooltipMainContainer #tooltip {
  display: none;
}

#tooltip::after {
  content: "";
  position: absolute;
  top: -16px;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #1E2939 transparent;
}

.list_hours::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.list_hours::-webkit-scrollbar-thumb {
  background: #CBCBCB;
  border-radius: 3px;
}

.list_hours::-webkit-scrollbar-track {
  inner-height: 5px;
  outer-height: 5px;
}

.list_hours::-webkit-scrollbar-thumb:hover {
  background-color: #5A5C60;
}


/* Close button hover style of custom modal */
#close_button:hover {
  background-color: #EEEEEE;
  border-radius: 6px;
}


/* MOdal Body Scroll */
#modal_body {
  overflow-y: auto;
  /* // cursor: "pointer",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-track ": {
    innerHeight: "5px",
    outerHeight: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: GREY.DISABLED,
    borderRadius: "3px",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: GREY.QUATINARY,
  }, */
}

#modal_body::-webkit-scrollbar {
  width: 5px;
  height: 5px
}

#modal_body::-webkit-scrollbar-track {
  inner-height: 5px;
  outer-height: 5px;
}

#modal_body::-webkit-scrollbar-thumb {
  background: #CBCBCB;
  border-radius: 3px;
}

#modal_body::-webkit-scrollbar-thumb:hover,
#modal_body::-webkit-scrollbar-thumb:hover {
  background-color: #5A5C60;
}
.more{
  cursor: pointer;
}

/* .tasks .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background: #ff4848;
 }
 .tasks .MuiInputBase-input.MuiInput-input.MuiInputBase-inputSizeSmall-MuiInputBase-input-MuiInput-input{
  background: #ff4848;
 } */



/* .onboarding{
 
  border-radius: 10px;
  -webkit-animation: cd-pulses 2s infinite;
  -moz-animation: cd-pulses 2s infinite;
  animation: cd-pulses 2s infinite;
  -webkit-animation-delay: .5s;
  -moz-animation-delay: .5s;
  animation-delay: .5s
   
}
 

@keyframes cd-pulses {
  0% {
		box-shadow: 0 0 0 0 #ff4848
	}

	100% {
    

 
		box-shadow: 0 0 0 20px transparent
	 
	}
} */


.true, .flase {
  margin: 0 5px;
  position: relative;
}

 

 .true {
  appearance: none;
  border: 1px solid #80F2BA;
  border-radius: 3px;
  margin: 0 5px;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 3px;
}
 .false {
  appearance: none;
  border:1px solid #FF9F9F;
  border-radius: 3px;
  margin: 0 5px;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 3px;
}

 .false:before {
  content: "\f00d";
  font: normal normal normal 14px/1 FontAwesome;
  color:#FF9F9F;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
  
 .false:checked::before {
  border: 1px solid #D3DAE3;
  background:#D3DAE3;
 width: 20px;
  line-height: 20px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
}

.true:before {
  content: "\f00c";
  font: normal normal normal 14px/1 FontAwesome;
  color:#80F2BA;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
  
 .true:checked::before {
  border: 1px solid #D3DAE3;
  background:#D3DAE3;
  width: 20px;
  line-height: 20px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
   
}
.true:disabled,.false:disabled {
  appearance: none;
  border: 1px solid #D3DAE3;
  background:#D3DAE3;
}
.true:disabled:before,.false:disabled:before {
  
  color:#fff;
  
}