.default:hover{
    border: 1px solid #a1a0a3 !important;
}
.default:hover {
    border: 1px solid #a1a0a3 !important;
}

.textarea {
    width: 100%;
    padding: 1rem;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    padding: 10px;
    background-color: #ffffff;
    color: #5f5f61;
}

.textAreaError {
    border: 1px solid var(--brand-primary) !important;
}

.textarea1 {
    width: 100%;
    padding: 1rem;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    padding: 10px;
    background-color: #ffffff;
    color: #5f5f61;
    resize: vertical;
}

.textarea:focus {
    border: 1px solid rgb(34, 33, 36) !important;
}

.textarea:hover {
    border: 1px solid #a1a0a3;
}

#inputField::placeholder {
    color: #A1A0A3 !important;
}

/* Textarea Body Scroll */
#textarea {
    overflow-y: auto;
}

#textarea::-webkit-scrollbar {
    width: 5px;
    height: 5px
}


#textarea::-webkit-scrollbar-thumb {
    background: #CBCBCB;
    border-radius: 3px;
}

#textarea::-webkit-scrollbar-thumb:hover,
#textarea::-webkit-scrollbar-thumb:hover {
    background-color: #5A5C60;
}

.dropdown {
    appearance: none;
    border: 1px solid #D3DAE3;
    /* padding: 0 1rem !important; */
    border-radius: 4px;
    color: #626B7E;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    background: transparent url('https://reachiq-email-template-images-new.s3.amazonaws.com/assets/assets/icons/Polygon.svg') no-repeat 95%;
    object-fit: cover;
    min-height: 5vh;
    height: 100%;
    margin: 0;
}

@media (max-width: 768px) {

    /* Media Query Styles */
    .dropdown {
        min-width: 20vw;
    }
}